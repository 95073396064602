///////////////////////////////////////////////////////////////////////////////
///////////////////////////////////////////////////////////////////////////////
///
/// @author:    José Antonio Yáñez Jiménez
/// @created:   08/02/2014
/// @modified:  09/02/2015
/// @name:      Media Print
/// @version:   1.8.0
/// @site:      www.jimenezfrontend.es
///
///////////////////////////////////////////////////////////////////////////////
///////////////////////////////////////////////////////////////////////////////

* {
  -webkit-print-color-adjust: exact;
}

#page-container {
  position: absolute;
  top: 0;
  left: 0;
  margin: 0;
  padding: 0;
  border: 0;
}

html,body{
    background:transparent !important;
}

#media-screen{
  display: none;
}

.backstretch {
    display: none;
}

#media-print{
  display: block;
}

@media print {
  @page {
    margin: 0;
  }

  html {
    margin: 0;
  }

  body {
    margin: 0;
    -webkit-print-color-adjust: exact;
  }

  #sidebar {
    display: none;
  }

  #page-container {
    width: auto;
    height: auto;
    overflow: visible;
    background-color: transparent;
  }

  .d {
    display: none;
  }
}

.pf {
  position: relative;
  margin: 0;
  border: 0;
  overflow: hidden;
  background-color: white;
}

.pc {
  display: block;
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  margin: 0;
  padding: 0;
  border: 0;
  overflow: hidden;
  -webkit-transform-origin: 0 0;
  transform-origin: 0 0;
  -ms-transform-origin: 0 0;
}

.pc.opened {
  display: block;
}

.bf {
  position: absolute;
  top: 0;
  bottom: 0;
  width: 100%;
  height: 100%;
  margin: 0;
  border: 0;
  -webkit-user-select: none;
  -moz-user-select: none;
  user-select: none;
  -ms-user-select: none;
}

.bi {
  position: absolute;
  margin: 0;
  border: 0;
  -webkit-user-select: none;
  -moz-user-select: none;
  user-select: none;
  -ms-user-select: none;
}

@media print {
  .pf {
    margin: 0;
    page-break-after: always;
    page-break-inside: avoid;
    box-shadow: none;
  }

  @-moz-document url-prefix() {
  .pf {
    border: 1px solid #fff;
    overflow: visible;
  }

  .pc {
    overflow: visible;
  }
}
}

.c {
  display: block;
  position: absolute;
  margin: 0;
  padding: 0;
  border: 0;
  overflow: hidden;
}

.t {
  position: absolute;
  font-size: 1px;
  white-space: pre;
  unicode-bidi: bidi-override;
  -webkit-transform-origin: 0 100%;
  transform-origin: 0 100%;
  -ms-transform-origin: 0 100%;
  -moz-font-feature-settings: "liga" 0;
}

._ {
  z-index: -1;
  color: transparent;
}

::selection {
  background: rgba(127,255,255,0.4);
}

::-moz-selection {
  background: rgba(127,255,255,0.4);
}

.pi {
  display: none;
}

.d {
  position: absolute;
  -webkit-transform-origin: 0 100%;
  transform-origin: 0 100%;
  -ms-transform-origin: 0 100%;
}

.ff0 {
  visibility: hidden;
  font-family: sans-serif;
}

@font-face {
  font-family: ff1;
  src: url('/fonts/amaranth-title.woff') format("woff");
}

.ff1 {
  visibility: visible;
  font-family: ff1;
  font-style: normal;
  font-weight: normal;
  line-height: 1.065000;
}

@font-face {
  font-family: raleway;
  src: url('/fonts/raleway.woff') format("woff");
}

.raleway {
  visibility: visible;
  font-family: raleway;
  font-style: normal;
  font-weight: normal;
  line-height: 0.965000;
}

@font-face {
  font-family: ff3;
  src: url('/fonts/amaranth-body.woff') format("woff");
}

.ff3 {
  visibility: visible;
  font-family: ff3;
  font-style: normal;
  font-weight: normal;
  line-height: 1.000000;
}

@font-face {
  font-family: ff4;
  src: url('/fonts/raleway-titles.woff') format("woff");
}

.ff4 {
  visibility: visible;
  font-family: ff4;
  font-style: normal;
  font-weight: normal;
  line-height: 0.955000;
}

@font-face {
  font-family: ff5;
  src: url('/fonts/brk-font.woff') format("woff");
}

.ff5 {
  visibility: visible;
  font-family: ff5;
  font-style: normal;
  font-weight: normal;
  line-height: 0.900821;
}

.ff7 {
  visibility: visible;
  font-family: raleway;
  font-style: normal;
  font-weight: normal;
  line-height: 0.964000;
}

.m2 {
  -webkit-transform: matrix(0.217330,0.072826,-0.079433,0.237045,0,0);
  -ms-transform: matrix(0.217330,0.072826,-0.079433,0.237045,0,0);
  transform: matrix(0.217330,0.072826,-0.079433,0.237045,0,0);
}

.m1 {
  -webkit-transform: matrix(0.222771,-0.053939,0.058832,0.242979,0,0);
  -ms-transform: matrix(0.222771,-0.053939,0.058832,0.242979,0,0);
  transform: matrix(0.222771,-0.053939,0.058832,0.242979,0,0);
}

.m4 {
  -webkit-transform: matrix(0.226480,-0.035254,0.038453,0.247025,0,0);
  -ms-transform: matrix(0.226480,-0.035254,0.038453,0.247025,0,0);
  transform: matrix(0.226480,-0.035254,0.038453,0.247025,0,0);
}

.m3 {
  -webkit-transform: matrix(0.236565,-0.007308,0.007720,0.249881,0,0);
  -ms-transform: matrix(0.236565,-0.007308,0.007720,0.249881,0,0);
  transform: matrix(0.236565,-0.007308,0.007720,0.249881,0,0);
}

.m0 {
  -webkit-transform: matrix(0.250000,0.000000,0.000000,0.250000,0,0);
  -ms-transform: matrix(0.250000,0.000000,0.000000,0.250000,0,0);
  transform: matrix(0.250000,0.000000,0.000000,0.250000,0,0);
}

.m5 {
  -webkit-transform: matrix(0.256980,0.008037,-0.007815,0.249878,0,0);
  -ms-transform: matrix(0.256980,0.008037,-0.007815,0.249878,0,0);
  transform: matrix(0.256980,0.008037,-0.007815,0.249878,0,0);
}

.m6 {
  -webkit-transform: matrix(0.268472,0.000000,0.000000,0.250000,0,0);
  -ms-transform: matrix(0.268472,0.000000,0.000000,0.250000,0,0);
  transform: matrix(0.268472,0.000000,0.000000,0.250000,0,0);
}

.v0 {
  vertical-align: 0px;
}

.ls1 {
  letter-spacing: -1.012000px;
}

.ls0 {
  letter-spacing: 0px;
}

.sc_ {
  text-shadow: none;
}

.sc0 {
  text-shadow: -0.015em 0 transparent,0 0.015em transparent,0.015em 0 transparent,0 -0.015em transparent;
}

._2 {
  display: inline;
  margin-left: -1270.336px;
}

._1 {
  display: inline;
  margin-left: -3.84px;
}

._0 {
  display: inline;
  margin-left: -1.68px;
}

._3 {
  display: inline-block;
  width: 1.3684px;
}

._4 {
  display: inline-block;
  width: 51.746px;
}

.fc2 {
  color: rgb(246,159,83);
}

.fc1 {
  color: rgb(133,29,47);
}

.fc0 {
  color: rgb(134,25,42);
}

body {
  margin: 0;
  padding: 0;
  font: 12pt "Tahoma";
  background-color: #FAFAFA;
}

* {
  -moz-box-sizing: border-box;
  box-sizing: border-box;
}

.page {
  width: 21cm;
  margin: 1cm auto;
  padding: 2cm;
  min-height: 29.7cm;
  border: 1px #D3D3D3 solid;
  border-radius: 5px;
  background: white;
  box-shadow: 0 0 5px rgba(0,0,0,0.1);
}

.subpage {
  height: 256mm;
  padding: 1cm;
  border: 5px red solid;
  outline: 2cm #FFEAEA solid;
}

@page {
    margin: 0;
    size: A4;
  }

  @media print {
  .page {
    width: initial;
    margin: 0;
    min-height: initial;
    border: initial;
    border-radius: initial;
    page-break-after: always;
    background: initial;
    box-shadow: initial;
  }
}

@media print {
  .v0 {
    vertical-align: 0pt;
  }

  .ls1 {
    letter-spacing: -1.349333pt;
  }

  .ls0 {
    letter-spacing: 0pt;
  }

  .ws1 {
    word-spacing: -1.493333pt;
  }

  .ws3 {
    word-spacing: -1.349333pt;
  }

  .ws0 {
    word-spacing: 0pt;
  }

  .ws2 {
    word-spacing: 1.349333pt;
  }

  ._2 {
    display: inline;
    margin-left: -1693.781333pt;
  }

  ._1 {
    display: inline;
    margin-left: -5.12pt;
  }

  ._0 {
    display: inline;
    margin-left: -2.24pt;
  }

  ._3 {
    display: inline-block;
    width: 1.824533pt;
  }

  ._4 {
    display: inline-block;
    width: 68.994667pt;
  }

  .fs2 {
    font-size: 42.666667pt;
  }

  .fsb {
    font-size: 53.333333pt;
  }

  .fs1 {
    font-size: 58.666667pt;
  }

  .fsa {
    font-size: 69.333333pt;
  }

  .fs9 {
    font-size: 74.666667pt;
  }

  .fs3 {
    font-size: 85.333333pt;
  }

  .fs4 {
    font-size: 101.333333pt;
  }

  .fs8 {
    font-size: 128.438768pt;
  }

  .fs7 {
    font-size: 151.807336pt;
  }

  .fs6 {
    font-size: 190.947671pt;
  }

  .fs5 {
    font-size: 218.666667pt;
  }

  .fs0 {
    font-size: 320pt;
  }

  .y0 {
    bottom: 0pt;
  }

  .y4b {
    bottom: 31.3652pt;
  }

  .y1f {
    bottom: 96.030267pt;
  }

  .y3e {
    bottom: 114.256533pt;
  }

  .y3d {
    bottom: 130.257867pt;
  }

  .y40 {
    bottom: 136.408933pt;
  }

  .y3c {
    bottom: 146.257867pt;
  }

  .y1e {
    bottom: 161.630267pt;
  }

  .y3b {
    bottom: 162.2552pt;
  }

  .y3a {
    bottom: 178.256533pt;
  }

  .y3f {
    bottom: 183.796933pt;
  }

  .y39 {
    bottom: 194.257867pt;
  }

  .y38 {
    bottom: 226.441467pt;
  }

  .y1d {
    bottom: 256.186133pt;
  }

  .y29 {
    bottom: 280.479867pt;
  }

  .y27 {
    bottom: 296.4772pt;
  }

  .y28 {
    bottom: 296.479867pt;
  }

  .y2a {
    bottom: 297.248667pt;
  }

  .y26 {
    bottom: 312.478533pt;
  }

  .y25 {
    bottom: 328.479867pt;
  }

  .y1b {
    bottom: 339.186133pt;
  }

  .y2c {
    bottom: 340.53pt;
  }

  .y24 {
    bottom: 344.479867pt;
  }

  .y23 {
    bottom: 360.4772pt;
  }

  .y22 {
    bottom: 376.478533pt;
  }

  .y2b {
    bottom: 388.358pt;
  }

  .y21 {
    bottom: 392.479867pt;
  }

  .y1c {
    bottom: 422.186133pt;
  }

  .y20 {
    bottom: 425.7448pt;
  }

  .y35 {
    bottom: 458.399067pt;
  }

  .y34 {
    bottom: 474.400400pt;
  }

  .y33 {
    bottom: 490.401733pt;
  }

  .y1a {
    bottom: 501.8528pt;
  }

  .y37 {
    bottom: 502.553467pt;
  }

  .y32 {
    bottom: 506.401733pt;
  }

  .y31 {
    bottom: 522.397733pt;
  }

  .y30 {
    bottom: 538.399067pt;
  }

  .y2f {
    bottom: 554.400400pt;
  }

  .y36 {
    bottom: 564.608133pt;
  }

  .y2e {
    bottom: 570.401733pt;
  }

  .y19 {
    bottom: 573.8528pt;
  }

  .y2d {
    bottom: 608.8268pt;
  }

  .yd {
    bottom: 655.923867pt;
  }

  .y16 {
    bottom: 655.925067pt;
  }

  .yb {
    bottom: 671.9212pt;
  }

  .yc {
    bottom: 671.923867pt;
  }

  .y15 {
    bottom: 671.925067pt;
  }

  .ya {
    bottom: 703.923867pt;
  }

  .y14 {
    bottom: 703.925067pt;
  }

  .y8 {
    bottom: 719.9212pt;
  }

  .y9 {
    bottom: 719.923867pt;
  }

  .y13 {
    bottom: 719.925067pt;
  }

  .y7 {
    bottom: 751.923867pt;
  }

  .y12 {
    bottom: 751.925067pt;
  }

  .y18 {
    bottom: 765.796933pt;
  }

  .y5 {
    bottom: 767.9212pt;
  }

  .y6 {
    bottom: 767.923867pt;
  }

  .y11 {
    bottom: 767.925067pt;
  }

  .y10 {
    bottom: 783.9224pt;
  }

  .y4 {
    bottom: 799.923867pt;
  }

  .y17 {
    bottom: 813.240933pt;
  }

  .y3 {
    bottom: 815.923867pt;
  }

  .yf {
    bottom: 815.925067pt;
  }

  .ye {
    bottom: 855.130267pt;
  }

  .y4a {
    bottom: 918.524133pt;
  }

  .y49 {
    bottom: 920.297467pt;
  }

  .y42 {
    bottom: 935.480533pt;
  }

  .y44 {
    bottom: 945.8516pt;
  }

  .y2 {
    bottom: 947.523467pt;
  }

  .y48 {
    bottom: 970.8528pt;
  }

  .y47 {
    bottom: 988.186133pt;
  }

  .y45 {
    bottom: 1022.035200pt;
  }

  .y1 {
    bottom: 1043.523467pt;
  }

  .y43 {
    bottom: 1056.5348pt;
  }

  .y41 {
    bottom: 1087.155733pt;
  }

  .y46 {
    bottom: 1097.630800pt;
  }

  .h4 {
    height: 31.573333pt;
  }

  .he {
    height: 39.466667pt;
  }

  .h3 {
    height: 43.413333pt;
  }

  .hd {
    height: 51.306667pt;
  }

  .hc {
    height: 55.253333pt;
  }

  .h5 {
    height: 66.474667pt;
  }

  .h6 {
    height: 78.938667pt;
  }

  .hb {
    height: 108.145442pt;
  }

  .h9 {
    height: 127.821777pt;
  }

  .ha {
    height: 131.94428pt;
  }

  .h8 {
    height: 160.777939pt;
  }

  .h7 {
    height: 175.058286pt;
  }

  .h2 {
    height: 269.44pt;
  }

  .h0 {
    height: 1122.52pt;
  }

  .h1 {
    height: 1122.666667pt;
  }

  .w0 {
    width: 793.706667pt;
  }

  .w1 {
    width: 794pt;
  }

  .x10 {
    left: 11.7148pt;
  }

  .x0 {
    left: 0pt;
  }

  .xb {
    left: 9.186pt;
  }

  .xe {
    left: 12.500533pt;
  }

  .xc {
    left: 12.216133pt;
  }

  .xf {
    left: 12.533867pt;
  }

  .x6 {
    left: 32.019467pt;
  }

  .xd {
    left: 45.808533pt;
  }

  .x7 {
    left: 50.546267pt;
  }

  .xa {
    left: 56.53pt;
  }

  .x1 {
    left: 59.6712pt;
  }

  .x1a {
    left: 138.5pt;
  }

  .x9 {
    left: 68.174533pt;
  }

  .x9alt {
    left: 58.174533pt;
  }

  .x21 {
    left: 70.490933pt;
  }

  .x1f {
    left: 92.294933pt;
  }

  .x1d {
    left: 58pt;
  }

  .x17 {
    left: 179.55pt;
  }

  .x16 {
    left: 225pt;
  }

  .x20 {
    left: 161.3972pt;
  }

  .x15 {
    left: 185.384133pt;
  }

  .x19 {
    left: 130pt;
  }

  .x8 {
    left: 232.720933pt;
  }

  .x11 {
    left: 244.442pt;
  }

  .x2 {
    left: 364.781867pt;
  }

  .x2alt {
    left: 360.781867pt;
  }

  .x2altb {
    left: 452.781867pt;
  }

  .x1b {
    left: 375.884267pt;
  }

  .x1c {
    left: 180pt;
  }

  .x18 {
    left: 401.470933pt;
  }

  .x1e {
    left: 417.184933pt;
  }

  .x5 {
    left: 447.691467pt;
  }

  .x22 {
    left: 462.057333pt;
  }

  .x26 {
    left: 464.4892pt;
  }

  .x2b {
    left: 472.218pt;
  }

  .x27 {
    left: 486.888667pt;
  }

  .x14 {
    left: 500pt;
  }

  .x29 {
    left: 513.888667pt;
  }

  .x13 {
    left: 527.998667pt;
  }

  .x23 {
    left: 543.278933pt;
  }

  .x4 {
    left: 553.110667pt;
  }

  .x3 {
    left: 557.248667pt;
  }

  .x12 {
    left: 571.248pt;
  }

  .x28 {
    left: 582.666667pt;
  }

  .x24 {
    left: 626.249333pt;
  }

  .x2a {
    left: 694.444667pt;
  }

  .x25 {
    left: 735.776pt;
  }

  .yearaward {
    left: 22.216133pt;
  }
}
